var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "nextcloud-widget nextcloud-user-status-wrapper" },
    [
      _vm.didLoadData
        ? _c(
            "div",
            { staticClass: "sep" },
            _vm._l(_vm.statuses, function (status, userId) {
              return _c("div", { key: userId, staticClass: "user" }, [
                _c("div", [
                  _c("div", [_c("i", [_vm._v(_vm._s(status.icon))])]),
                  _c("div", [
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.clearAtTooltip(status.clearAt),
                            expression: "clearAtTooltip(status.clearAt)",
                          },
                        ],
                      },
                      [
                        _c("strong", [_vm._v(_vm._s(status.userId))]),
                        _vm._v("  "),
                        status.clearAt
                          ? _c("small", [
                              _c("i", { staticClass: "fal fa-clock" }),
                            ])
                          : status.message
                          ? _c("span", [_vm._v("•")])
                          : _vm._e(),
                        _c("em", [_vm._v(_vm._s(status.message))]),
                      ]
                    ),
                  ]),
                  _c("div", [
                    _c("p", [
                      _c("small", { class: `status ${status.status}` }, [
                        status.status === "online" || status.status === "dnd"
                          ? _c("i", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.tt(status.status),
                                  expression: "tt(status.status)",
                                },
                              ],
                              staticClass: "fas fa-circle",
                            })
                          : _c("i", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.tt(status.status),
                                  expression: "tt(status.status)",
                                },
                              ],
                              staticClass: "far fa-circle",
                            }),
                      ]),
                    ]),
                  ]),
                ]),
                _c("hr"),
              ])
            }),
            0
          )
        : _c("div", { staticClass: "sep" }, [
            _c("p", [_vm._v(_vm._s(_vm.tt("nothing-to-show")))]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }